import React, { useState, useEffect, useContext } from "react";
import abi_logo from "../../assets/images/abi_logo.png";
import jean_logo from "../../assets/images/JeanLogo.png";
import abi_logo_short from "../../assets/images/abi_logo_short.png";
import defaultProfilePhoto from "../../assets/images/profile.jpg";
import { AuthContext } from "../../contexts/auth";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  FaHome,
  FaChartBar,
  FaPlusCircle,
  FaCog,
  FaChevronDown,
  FaBars,
  FaBookReader,
} from "react-icons/fa";
import { BiHelpCircle } from "react-icons/bi";
import { MdReviews } from "react-icons/md";
import "../../assets/styles/navbar.css";
import languageOptions from "../../common/translations/languageOptions";
import SwitchZone from "../switchZone";
//for multi-lingual support
import { useTranslation } from "react-i18next";

const Navigation = () => {
  const [showIconSubmenu, setShowIconSubmenu] = useState(false);
  const [isMiddle1Transformed, setIsMiddle1Transformed] = useState(false);
  const { user, logout } = useContext(AuthContext);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const [profilePhoto, setProfilePhoto] = useState(null);

  let userZonesTemp = [];

  //multi-lingual
  const options = languageOptions;

  const { i18n, t } = useTranslation();

  const handleChangeLanguage = (event) => {
    i18n.changeLanguage(event);
  };

  const toggleMiddle1Transformation = () => {
    setIsMiddle1Transformed(!isMiddle1Transformed);
  };

  const toggleIconSubmenu = () => {
    setShowIconSubmenu(!showIconSubmenu);
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 1240) {
        setIsMiddle1Transformed(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const url = window.location.pathname;
  const showZone = !url.startsWith("/cognosmje");
  const page = url.substring(4);
  let jeanUsers = localStorage.getItem("jean_roles");

  let role = localStorage.getItem("jean_roles")
    ? localStorage.getItem("jean_roles") !== "undefined"
      ? localStorage.getItem("jean_roles")
      : ""
    : localStorage.getItem("jean_roles");

  let zone = localStorage.getItem("mje_zone_selected")
    ? localStorage.getItem("mje_zone_selected") !== "undefined"
      ? localStorage.getItem("mje_zone_selected")
      : ""
    : localStorage.getItem("mje_zone_selected");
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthorized = localStorage.getItem("mje_authorized")
    ? localStorage.getItem("mje_authorized") !== "undefined"
      ? localStorage.getItem("mje_authorized")
      : ""
    : localStorage.getItem("mje_authorized");

  const checkRoleZone = () => {
    if (
      role == undefined ||
      role == null ||
      role == "" ||
      zone == undefined ||
      zone == null ||
      zone == "" ||
      isAuthorized == false ||
      isAuthorized == null ||
      isAuthorized == ""
    ) {
      navigate("/v2/home");
    }
  };

  useEffect(() => {
    checkRoleZone();
    setProfilePhoto(localStorage.getItem("ImgData"));
  }, []);

  useEffect(() => {}, [location]);

  return (
    <>
      <div className="all">
        <div className="navigation">
          <div className="left">
            <img
              src={jean_logo}
              alt="jean-logo"
              className="jean-logo"
              onClick={() => navigate("/v2/home")}
            />
            <div className="line">
              <div className="line1"></div>
            </div>
            <img
              src={abi_logo}
              alt="Logo"
              className="abi-logo"
              onClick={() => navigate("/v2/home")}
            />
            <img
              src={abi_logo_short}
              alt="Logo"
              className="abi-logo-short"
              onClick={() => navigate("/v2/home")}
            />
          </div>
          <div className="middle">
            {(jeanUsers.toLowerCase().includes("requestor") ||
              jeanUsers.toLowerCase().includes("reviewer") ||
              jeanUsers.toLowerCase().includes("approver")) &&
            zone.toLowerCase() !== "global" ? (
              <>
                <a
                  href="/v2/dashboardPage"
                  className="link"
                  id={page.startsWith("dashboardPage") ? "make-golden" : ""}
                >
                  <FaHome
                    className="icon-middle"
                    id={page.startsWith("dashboardPage") ? "make-golden" : ""}
                  />{" "}
                  {t("navbar_home")}
                </a>
              </>
            ) : (
              <></>
            )}
            {
              <a
                href="/v2/jeanInsights"
                className="link"
                id={page.startsWith("insights") ? "make-golden-icon" : ""}
              >
                <FaChartBar
                  className="icon-middle"
                  id={page.startsWith("insights") ? "make-golden" : ""}
                />{" "}
                {t("navbar_insights")}
              </a>
            }
            {role?.includes("Requestor") ? (
              <a
                href="/v2/newrequest"
                className="link"
                id={page.startsWith("newrequest") ? "make-golden" : ""}
              >
                <FaPlusCircle
                  className="icon-middle"
                  id={page.startsWith("newrequest") ? "make-golden" : ""}
                />{" "}
                {t("navbar_new_req")}
              </a>
            ) : (
              ""
            )}
            {(jeanUsers.toLowerCase().includes("sox reviewer") ||
              jeanUsers.toLowerCase().includes("mje reviewer")) &&
              zone.toLowerCase().includes("global") && (
                <a
                  href="/v2/detective-review"
                  className="link"
                  id={
                    page.startsWith("sox-review") ||
                      page.startsWith("mje-review") ||
                      page.startsWith("detective-review")
                      ? "make-golden"
                      : ""
                  }
                >
                  <FaBookReader
                    className="icon-middle"
                    id={
                      page.startsWith("sox-review") ||
                        page.startsWith("mje-review") ||
                        page.startsWith("detective-review")
                        ? "make-golden"
                        : ""
                    }
                  />{" "}
                  {t("detective_controls_title")}
                </a>
              )}
            {(jeanUsers.toLowerCase().includes("requestor") ||
              jeanUsers.toLowerCase().includes("reviewer") ||
              jeanUsers.toLowerCase().includes("approver")) &&
              zone.toLowerCase() !== "global" && (
                <a
                  href="/v2/journal-repository"
                  className="link"
                  id={
                    page.startsWith("journal-repository") ? "make-golden" : ""
                  }
                >
                  <BiHelpCircle
                    className="icon-middle"
                    id={
                      page.startsWith("journal-repository") ? "make-golden" : ""
                    }
                  />{" "}
                  {t("navbar_journal_repository")}
                </a>
              )}
            {(jeanUsers.toLowerCase()?.includes("admin") ||
              jeanUsers.toLowerCase()?.includes("zone controller") ||
              jeanUsers.toLowerCase()?.includes("it reader") ||
              jeanUsers.toLowerCase()?.includes("internal controls")) && (
              <a
                href="/v2/manage-hub"
                className="link"
                id={page.startsWith("manage-hub") ? "make-golden" : ""}
              >
                <FaCog
                  className="icon-middle"
                  id={page.startsWith("manage-hub") ? "make-golden" : ""}
                />{" "}
                {t("navbar_manage_hub")}
              </a>
            )}

            <SwitchZone />
          </div>
          <div className="right">
            <div className="iconuser">
              <img
                className="avatar profile-photo"
                src={profilePhoto ? profilePhoto : defaultProfilePhoto}
                alt="profile"
              />
            </div>
            <div className="text" style={{ marginLeft: "5px" }}>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "12px",
                  fontFamily: "Avantt-SemiBold",
                  marginBottom: "5px",
                }}
              >
                {accounts?.length > 0
                  ? accounts[0]?.name
                  : localStorage.getItem("accountName")}
              </p>
              <p
                style={{
                  fontSize: "12px",
                  fontFamily: "Avantt-SemiBold",
                  color: "goldenrod",
                  textAlign: "left",
                }}
              >
                {role.replace("Controllers", "Controls/Reporting")}{" "}
              </p>
            </div>
            <div
              className={`hamburger ${isMiddle1Transformed ? "ham" : ""}`}
              onClick={toggleMiddle1Transformation}
            >
              <FaBars />
            </div>
            <div className="icondown" onClick={toggleIconSubmenu}>
              <FaChevronDown />
              {showIconSubmenu && (
                <div
                  className="dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: "50px",
                    right: "15px",
                  }}
                >
                  {
                    <a
                      href="/v2/user-profile"
                      className="dropdown-item text-left"
                    >
                      {t("navbar_my_profile")}
                    </a>
                  }
                  <a href="/v2/my-guide" className="dropdown-item text-left">
                    {t("navbar_guide")}
                  </a>
                  <a className="dropdown-item text-left" href="/v2/contactus">
                    {t("navbar_support")}
                  </a>

                  <a className="dropdown-item text-left" onClick={handleLogout}>
                    {t("navbar_sign_out")}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`middle1 ${isMiddle1Transformed ? "transformed" : ""}`}>
          {(jeanUsers.toLowerCase().includes("requestor") ||
            jeanUsers.toLowerCase().includes("reviewer") ||
            jeanUsers.toLowerCase().includes("approver")) &&
            zone.toLowerCase() !== "global" && (
              <a href="/v2/dashboardPage" className="link2 navbar-a">
                <FaHome className="icon-middle1" /> {t("navbar_home")}
              </a>
            )}
          {
            <a href="/v2/jeanInsights" className="link2 navbar-a">
              <FaChartBar className="icon-middle1" /> {t("navbar_insights")}
            </a>
          }

          {role?.includes("Requestor") &&
            !zone.toLowerCase().includes("global") && (
              <a href="/v2/newrequest" className="link2 navbar-a">
                <FaPlusCircle className="icon-middle1" /> {t("navbar_new_req")}
              </a>
            )}
          {(jeanUsers.toLowerCase().includes("sox reviewer") ||
            jeanUsers.toLowerCase().includes("mje reviewer")) &&
            zone.toLowerCase().includes("global") && (
              <a href="/v2/detective-review" className="link2 navbar-a">
                <FaBookReader className="icon-middle1" />{" "}
                {t("detective_controls_title")}
              </a>
            )}
          {(jeanUsers.toLowerCase().includes("requestor") ||
            jeanUsers.toLowerCase().includes("reviewer") ||
            jeanUsers.toLowerCase().includes("approver")) &&
            zone.toLowerCase() !== "global" && (
              <a href="/v2/journal-repository" className="link2 navbar-a">
                <BiHelpCircle className="icon-middle1" />{" "}
                {t("navbar_journal_repository")}
              </a>
            )}

          {(role?.includes("Admin") ||
            role?.toLowerCase()?.includes("it reader") ||
            role?.includes("Internal Controls") ||
            role?.includes("Zone Controller")) && (
            <a href="/v2/manage-hub" className="link2 navbar-a">
              <FaCog className="icon-middle1" /> {t("navbar_manage_hub")}
            </a>
          )}

          <SwitchZone />
        </div>
        <div className="golden-bar-below-navbar"></div>
      </div>
    </>
  );
};

export default Navigation;
