const tenant_id = process.env.REACT_APP_TENANT_ID;
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: "https://login.microsoftonline.com/" + tenant_id, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: `${window.location.origin}`,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [
    "User.Read",
    "email",
    "Directory.Read.All",
    "Application.Read.All",
    "Group.Read.All",
    "Group.ReadWrite.All",
  ],
};

const SNOW_API_CLIENT_ID = process.env.REACT_APP_SNOW_CLIENT_ID;

export const snowBackendRequest = {
  scopes: ["api://" + SNOW_API_CLIENT_ID + "/access_as_user"],
};
const NPS_API_CLIENT_ID = process.env.REACT_APP_NPS_AUTH_API;

export const npsBackendRequest = {
  scopes: ["api://" + NPS_API_CLIENT_ID + "/access_as_user"],
};

export const pbiBackendRequest = {
  scopes: [process.env.REACT_APP_PBI_SCOPE],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  graphProfilePhotoEndpoint: "https://graph.microsoft.com/v1.0/me/photo/$value",
};
