import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import "../../../assets/styles/TopBar.css";
import FeatherIcon from "feather-icons-react";
import abiLogo from "../../../assets/images/abi_logo.png";
import appLogo from "../../../assets/images/Jean_Color_logo_ White_Txt.png";
import defaultProfilePhoto from "../../../assets/images/profile.jpg";
import { AuthContext } from "../../../contexts/auth";
import { IconBulb } from "@tabler/icons";
import SwitchZone from "../../switchZone";
import getGroups from "../../../components/home/getGroups";

const TopBar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useContext(AuthContext);
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();
  const [isDropDownOpen, setisDropDownOpen] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [isHeaderOpen, setisHeaderOpen] = useState(false);

  const togglingDropDown = () => {
    setisDropDownOpen(!isDropDownOpen);
  };

  const togglingHeader = () => {
    setisHeaderOpen(!isHeaderOpen);
  };

  const handleLogout = () => {
    logout();
  };

  useEffect(() => {
    setProfilePhoto(localStorage.getItem("ImgData"));
  }, [location]);

  const [selectedZone, setSelectedZone] = useState(
    localStorage.getItem("mje_zone_selected")
  );

  const [userZonesOptions, setUserZonesOptions] = useState([]);
  let userZonesTemp = [];
  let newArr = [];
  const [executions, setExecutions] = useState(0);
  useEffect(() => {
    if (executions < 4) {
      const timeoutId = setTimeout(() => {
        let userZones = [];
        if (
          localStorage.getItem("mje_authorized") === "true" &&
          localStorage.getItem("mje_zones")
        ) {
          userZonesTemp = localStorage.getItem("mje_zones").split(",");
          userZonesTemp.forEach((item, index) => {
            const myObj = {
              id: index,
              mje_zone_name: item.split("_")[2],
            };
            userZones.push(myObj);
          });

          for (const obj of userZones) {
            const newObj = {};
            for (const [key, value] of Object.entries(obj)) {
              if (key === "id") {
                newObj.key = value;
              } else if (key === "mje_zone_name") {
                newObj.value = value;
                newObj.label = value;
              }
            }
            newArr.push(newObj);
          }
        }
        setUserZonesOptions(newArr);
        setExecutions(executions + 1);
      }, 5000);

      return () => clearTimeout(timeoutId);
    }
  }, [executions]);

  const handleChangeButtonClick = async (event) => {
    localStorage.setItem("mje_zone_selected", event);
    setSelectedZone(event);
    await getGroups(event);
    const zone = localStorage.getItem("mje_zone_selected");
    if (zone == "GLOBAL") {
      if (localStorage.getItem("jean_roles") === "SOX Reviewer") {
        window.location.href = "/v2/sox-review";
      } else {
        window.location.href = "/v2/jeanInsights";
      }
    } else {
      window.location.href = "/v2/dashboardPage";
    }
  };
  return (
    <div className="top-nav">
      <div className="header py-4">
        <div className="container">
          <div className="d-flex">
            <a className="header-brand" href="/">
              <img
                src={appLogo}
                className="header-brand-img"
                style={{ height: "2.5rem", marginRight: "1rem" }}
                alt="App Logo"
              />
              <img
                src={abiLogo}
                className="header-brand-img"
                alt="AB InBev Logo"
                style={{
                  borderLeft: "1px solid #c9c9c9",
                  paddingLeft: "0.5rem",
                  height: "1.5rem",
                }}
              />
            </a>
            <div
              className="d-flex order-lg-2 ml-auto text-left"
              style={{ marginTop: "auto", marginBottom: "auto" }}
            >
              <span
                className="golden-text"
                style={{ marginTop: "auto", marginBottom: "auto" }}
              >
                {process.env.REACT_APP_STAGE == "prod" ? (
                  <strong>{`LIVE`}</strong>
                ) : (
                  <strong>{`BETA`}</strong>
                )}
              </span>
              <div className="dropdown">
                <a
                  className="nav-link pr-0 leading-none"
                  onClick={togglingDropDown}
                >
                  <img
                    className="avatar"
                    src={profilePhoto ? profilePhoto : defaultProfilePhoto}
                    alt=""
                  />
                  <span className="ml-2 d-none d-lg-block">
                    <span className="golden-text">
                      <strong>
                        {accounts?.length > 0
                          ? accounts[0]?.name
                          : localStorage.getItem("accountName")}
                      </strong>
                    </span>
                    <span>
                      &emsp;&emsp;&emsp;
                      <FeatherIcon icon="chevron-down" size={14} />
                    </span>
                    <small className="text-muted d-block mt-1">
                      {accounts?.length > 0
                        ? accounts[0]?.username
                        : localStorage.getItem("accountUserName")}
                    </small>
                    <small className="golden-text">
                      {localStorage.getItem("roles")
                        ? localStorage.getItem("roles") !== "undefined"
                          ? localStorage.getItem("roles")
                          : ""
                        : localStorage.getItem("roles")}
                    </small>
                  </span>
                </a>
                {isDropDownOpen && (
                  <div
                    className="dropdown-menu dropdown-menu-right dropdown-menu-arrow show"
                    style={{ cursor: "pointer" }}
                  >
                    <a className="dropdown-item text-left" href="/contactus">
                      Support
                    </a>
                    <a
                      className="dropdown-item text-left"
                      onClick={handleLogout}
                    >
                      Sign out
                    </a>
                  </div>
                )}
              </div>
            </div>
            <a
              style={{ marginTop: "auto", marginBottom: "auto" }}
              className={`header-toggler d-lg-none ml-3 ml-lg-0 ${
                isHeaderOpen ? "" : "collapsed"
              }`}
              onClick={togglingHeader}
            >
              <span className="header-toggler-icon"></span>
            </a>
          </div>
        </div>
      </div>
      <div
        className={`header collapse d-lg-flex p-0 ${
          isHeaderOpen ? "show" : ""
        }`}
        id="headerMenuCollapse"
        style={{ background: "linear-gradient(90deg,#e3af32 0%,#f4e00f 100%)" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg order-lg-first">
              <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      ["/"].includes(location?.pathname) ? " active" : ""
                    }`}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <FeatherIcon icon="home" size={14} />
                    &nbsp;{"Home"}
                  </a>
                </li>
                {user?.role?.split(",").filter((e) => e === "Requester")
                  ?.length > 0 && (
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        ["/request/create"].includes(location?.pathname)
                          ? " active"
                          : ""
                      }`}
                      // href={window.location.origin + '/request/create'}
                      onClick={() => {
                        navigate("/request/create");
                      }}
                      // target="_blank"
                    >
                      <FeatherIcon icon="plus-circle" size={14} />
                      &nbsp;{"New Request"}
                    </a>
                  </li>
                )}
                {(user?.role === "Requester" || user?.role === "Reviewer") && (
                  <li className="nav-item">
                    <FeatherIcon icon="bar-chart" size={14} color="black" />
                    <a
                      href="https://app.powerbi.com/groups/me/reports/f1899358-76a0-4651-af61-aa8ac617abc9/ReportSectionfb17c6e55a0d30500f4a"
                      target="_blank"
                      className={`nav-link`}
                    >
                      &nbsp;{"JEAN Power BI"}
                    </a>
                  </li>
                )}
                {
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        ["/cognosmje-reviewer"].includes(location?.pathname)
                          ? " active"
                          : ""
                      }`}
                      onClick={() => {
                        navigate("/cognosmje-reviewer");
                      }}
                    >
                      <FeatherIcon icon="user-check" size={14} />
                      &nbsp;{"COGNOS MJE Reviewer"}
                    </a>
                  </li>
                }

                {
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        ["/cognosmje-approver"].includes(location?.pathname)
                          ? " active"
                          : ""
                      }`}
                      onClick={() => {
                        navigate("/cognosmje-approver");
                      }}
                    >
                      <FeatherIcon icon="check-circle" size={14} />
                      &nbsp;{"COGNOS MJE Approver"}
                    </a>
                  </li>
                }
                {user?.role?.split(",").filter((e) => e === "COGNOS_Admin")
                  ?.length > 0 && (
                  <>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          ["/cognosmje-admin"].includes(location?.pathname)
                            ? " active"
                            : ""
                        }`}
                        onClick={() => {
                          navigate("/cognosmje-admin");
                        }}
                      >
                        <FeatherIcon icon="briefcase" size={14} />
                        &nbsp;{"COGNOS MJE Admin"}
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        href="https://app.powerbi.com/links/feM4dw-Gv_?ctid=cef04b19-7776-4a94-b89b-375c77a8f936&pbi_source=linkShare"
                        target="_blank"
                        className={`nav-link `}
                      >
                        <IconBulb size={14} color="black" />
                        &nbsp;{"INSIGHT"}
                      </a>
                    </li>
                  </>
                )}
                {parseInt(localStorage.getItem("mje_zones_count")) > 0 &&
                  localStorage.getItem("roles") !== "" && <SwitchZone />}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
