export const CheckUserZones = async (
  userZonesTemp,
  newArr,
  setUserZonesOptions
) => {
  let userZones = [];
  userZonesTemp = localStorage.getItem("mje_zones")?.split(",");
  userZonesTemp.forEach((item, index) => {
    const myObj = {
      id: index,
      mje_zone_name: item.split("_")[2],
    };
    userZones.push(myObj);
  });

  for (const obj of userZones) {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (key === "id") {
        newObj.key = value;
      } else if (key === "mje_zone_name") {
        if (value === "SA") {
          newObj.value = value;
          newObj.label = "AFR";
        } else if (value === "EUZ") {
          newObj.value = value;
          newObj.label = "EUR/GHQ";
        } else if (value === "NAZ") {
          newObj.value = "NAZ";
          newObj.label = "NAZ";
        } else {
          newObj.value = value;
          newObj.label = value;
        }
      }
    }
    newArr.push(newObj);
  }
  const nazUsers = localStorage.getItem("roles");
  if (nazUsers.includes("Requester") || nazUsers.includes("Reviewer")) {
    const newObj = {};
    newObj.key = 10;
    newObj.value = "NAZ 1.0";
    newObj.label = "NAZ 1.0";
    newArr.push(newObj);
  }
  const newObj = {};
  newObj.key = 11;
  newObj.value = "COGNOS MJE";
  newObj.label = "COGNOS MJE";
  newArr.push(newObj);
  setUserZonesOptions(newArr);
};
