import React, { lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";

const Login = lazy(() => import("../pages/login"));

export default function LoggedOutRoutes() {
  return (
    <Suspense fallback={<div />}>
      <Routes>
        <Route exact path={`/`} element={<Login />} />
        <Route path={`*`} element={<Navigate to={`/`} />} />
      </Routes>
    </Suspense>
  );
}
