import React, { lazy, Suspense, useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { RequestsProvider } from "../contexts/requests";
import { CognosmjeProvider } from "../contexts/cognosmje";
import { CognosmjeAdminProvider } from "../contexts/cognosmjeAdmin";
import { CognosmjeApproverProvider } from "../contexts/cognosmjeApprover";
import { AuthContext } from "../contexts/auth";
import "../assets/styles/App.css";

const Requests = lazy(() => import("../pages/requests"));
const RequestProcess = lazy(() => import("../pages/request-process"));
const Reviewer = lazy(() => import("../pages/reviewer/index"));
const NotFOund = lazy(() => import("../pages/NoMatch/NoMatch"));
const CognosmjeReviewer = lazy(() => import("../pages/cognosmje-reviewer"));
const CognosmjeAdmin = lazy(() => import("../pages/cognosmje-admin"));
const CognosmjeApprover = lazy(() => import("../pages/cognosmje-approver"));
const Home = lazy(() => import("../pages/home"));
const JeanHome = lazy(() => import("../pages/home/home"));
const UserProfile = lazy(() => import("../pages/userProfile/UserProfile"));
const NewRequestForm = lazy(() => import("../pages/new-request/NewRequest"));
const ValidationScreen = lazy(() =>
  import("../pages/new-request/validationPage")
);
const JeanRepo = lazy(() => import("../pages/repository/jeanRepo"));
const DashboardPage = lazy(() =>
  import("../components/Dashboard/dashBoardPage")
);
const RiskValidation = lazy(() =>
  import("../components/RiskReview/riskValidation")
);
const ManageHub = lazy(() => import("../components/ManageHub/manageHub"));
const SoxReview = lazy(() => import("../components/SoXReview/soxReview"));
const MjeReview = lazy(() => import("../components/SoXReview/MjeReview"));
const DetectiveReview = lazy(() =>
  import("../pages/detective-review/detectiveReview")
);

const ContactUs = lazy(() => import("../components/contactus/contactus"));

const MyGuide = lazy(() => import("../components/MyGuide/Guide"));

const Error500 = lazy(() => import("../pages/NoMatch/Error500"));

const InsightPage = lazy(() => import("../pages/insights/insights"));

const RequesterRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  if (user?.role?.split(",").filter((e) => e === "Reviewer")?.length > 0) {
    return <Navigate to={"/reviewer"} />;
  }
  return children;
};

const ReviewerRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  if (user?.role?.split(",").filter((e) => e === "Requester")?.length > 0) {
    return <Navigate to={"/requester"} />;
  }
  return children;
};

const CognosmjeReviewerRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  return children;
};

const CognosmjeApproverRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  return children;
};

const CognosmjeAdminRoute = ({ children }) => {
  const { user } = useContext(AuthContext);

  if (user?.role?.split(",").filter((e) => e === "COGNOS_Admin")?.length > 0) {
    return children;
  }
  return <NotFOund />;
};
export default function LoggedInRoutes() {
  return (
    <Suspense fallback={<div />}>
      <RequestsProvider>
        <Routes>
          <Route path={`/`} element={<Navigate to={`/v2/home`} />} />
          <Route exact path={`/home`} element={<Home />} />
          <Route exact path={`/v2/home`} element={<JeanHome />} />
          <Route exact path={`/v2/newrequest`} element={<NewRequestForm />} />
          <Route exact path={`/v2/dashboardPage`} element={<DashboardPage />} />
          <Route exact path={`/v2/jeanInsights`} element={<InsightPage />} />
          <Route exact path={`/v2/journal-repository`} element={<JeanRepo />} />
          <Route
            exact
            path={`/v2/riskReview/:id`}
            element={<RiskValidation />}
          />
          <Route exact path={`/v2/error`} element={<Error500 />} />
          <Route exact path={`/v2/manage-hub`} element={<ManageHub />} />
          <Route exact path={`/v2/sox-review`} element={<SoxReview />} />
          <Route exact path={`/v2/mje-review`} element={<MjeReview />} />
          <Route
            exact
            path={`/v2/detective-review`}
            element={<DetectiveReview />}
          />

          <Route exact path={`/v2/user-profile`} element={<UserProfile />} />
          <Route exact path={`/v2/my-guide`} element={<MyGuide />} />
          <Route
            exact
            path="/v2/validation/:id"
            element={<ValidationScreen />}
          />
          <Route exact path="/v2/contactus" element={<ContactUs />} />
          <Route exact path="/contactus" element={<ContactUs />} />
          <Route
            exact
            path={`/cognosmje-reviewer`}
            element={
              <CognosmjeProvider>
                <CognosmjeReviewerRoute>
                  <CognosmjeReviewer />
                </CognosmjeReviewerRoute>
              </CognosmjeProvider>
            }
          />
          <Route
            exact
            path={`/cognosmje-approver`}
            element={
              <CognosmjeApproverProvider>
                <CognosmjeApproverRoute>
                  <CognosmjeApprover />
                </CognosmjeApproverRoute>
              </CognosmjeApproverProvider>
            }
          />
          <Route
            exact
            path={`/cognosmje-admin`}
            element={
              <CognosmjeAdminRoute>
                <CognosmjeAdminProvider>
                  <CognosmjeAdmin />
                </CognosmjeAdminProvider>
              </CognosmjeAdminRoute>
            }
          />
          <Route
            exact
            path={`/requester`}
            element={
              <RequesterRoute>
                <Requests />
              </RequesterRoute>
            }
          />
          <Route
            exact
            path={`/request/create`}
            element={
              <RequesterRoute>
                <RequestProcess />
              </RequesterRoute>
            }
          />
          <Route exact path={`/request/:id`} element={<RequestProcess />} />
          <Route
            exact
            path={`/reviewer`}
            element={
              <ReviewerRoute>
                <Reviewer />
              </ReviewerRoute>
            }
          />
          <Route path={`*`} element={<NotFOund />} />
        </Routes>
      </RequestsProvider>
    </Suspense>
  );
}
