import React, { createContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import {
  loginRequest,
  snowBackendRequest,
  npsBackendRequest,
  pbiBackendRequest,
} from "../constants/authconfig";
import pureAxios from "axios";
import { axios, Jeanaxios } from "../utils/axios";
import Cookies from "js-cookie";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("token"));
  const [user, setUser] = useState(null);

  const { instance, accounts } = useMsal();

  useEffect(() => {
    async function requestProfileData() {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(request)
        .then(async (response) => {
          localStorage.setItem("accountUserName", response.account.username);
          localStorage.setItem("accountName", response.account.name);
          localStorage.setItem("token", response.accessToken);
          localStorage.setItem("roles", response.idTokenClaims.roles);

          //get the groups to which users belong for jean 2.0
          await getGroups();

          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.accessToken}`;
          axios.defaults.headers.common["Role"] = response.idTokenClaims.roles;

          // defaults for JEAN 2.0

          Jeanaxios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${response.accessToken}`;

          setLoggedIn(true);
        })
        .catch((e) => {});
    }

    if (accounts[0]) {
      requestProfileData();
    }
  }, [accounts, instance]);

  useEffect(() => {
    setLoggedIn(!!localStorage.getItem("token"));
  }, [loggedIn]);

  useEffect(() => {
    if (loggedIn) {
      getGroups();
      getProfile();
      getProfilePhoto();
      getSnowToken();
      getNpsToken();
      getPBIToken();
    } else {
      // navigate('/')
    }
  }, [loggedIn]);

  const login = () => {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  };

  //get snow token for incidents
  const getSnowToken = () => {
    const snowRequest = {
      ...snowBackendRequest,
      account: accounts[0],
    };

    if (accounts?.length > 0) {
      instance
        .acquireTokenSilent(snowRequest)
        .then((response2) => {
          Jeanaxios.defaults.headers.common[
            "Snowtoken"
          ] = `${response2.accessToken}`;
          localStorage.setItem("snow_api_access_token", response2.accessToken);
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
        });
    }
  };
  //get token for NPS service
  const getNpsToken = () => {
    const npsRequest = {
      ...npsBackendRequest,
      account: accounts[0],
    };

    if (accounts?.length > 0) {
      instance
        .acquireTokenSilent(npsRequest)
        .then((response2) => {
          localStorage.setItem("nps-auth-token", response2.accessToken);
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
        });
    }
  };
  //get token for PBI service
  const getPBIToken = () => {
    const pbiRequest = {
      ...pbiBackendRequest,
      account: accounts[0],
    };

    if (accounts?.length > 0) {
      instance
        .acquireTokenSilent(pbiRequest)
        .then((response) => {
          localStorage.setItem("powerbi_access_token", response?.accessToken);
        })
        .catch((err) => {
          console.log(`Error occured while acquiring token: ${err}`);
        });
    }
  };
  //get Employee ID
  const getEmpId = (auth_url, headers, user_objId) => {
    pureAxios
      .get(
        auth_url +
          "users/" +
          user_objId +
          "?$select=extension_fb8282b27e2d4ab39399babbd9a4861a_abiGlobalID",
        {
          headers,
        }
      )
      .then(
        (res) => {
          const emp_id =
            res.data.extension_fb8282b27e2d4ab39399babbd9a4861a_abiGlobalID;
          localStorage.setItem("employee_id", emp_id);
        },
        (error) => {
          if (error.response.status === 401) {
            if (localStorage.getItem("token")) {
              localStorage.clear();
              window.location.href = "/";
            }
          }
        }
      );
  };

  //get the groups for the user
  const getGroups = async () => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    await pureAxios
      .get(
        "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$top=999",
        {
          headers,
        }
      )
      .then(
        async (res) => {
          const user_groups = res?.data.value;

          //first check if the user has any access at all
          const jean_global = await user_groups.filter((item) => {
            return item.id === process.env.REACT_APP_JEAN_APP_ID;
          });
          if (jean_global.length !== 0) {
            localStorage.setItem("mje_authorized", true);
            //secondly check which zone and roles does the user have access to
            const jean_zone_access = await user_groups.filter((item) => {
              const zoneIds = Object.keys(process.env).filter((key) =>
                key.endsWith("_ZONE_ID")
              );

              return (
                item.id &&
                zoneIds.some((zoneId) => item.id === process.env[zoneId])
              );
            });

            if (jean_zone_access !== 0) {
              localStorage.setItem("mje_zones_count", jean_zone_access.length);
              const zones = [];
              jean_zone_access.map((group_name) => {
                return zones.push(group_name.displayName);
              });
              localStorage.setItem("mje_zones", zones);
            }
          } else {
            localStorage.setItem("mje_authorized", false);
          }
        },
        (error) => {
          if (error.response.status === 401) {
            if (localStorage.getItem("token")) {
              localStorage.clear();
              window.location.href = "/";
            }
          }
        }
      );
  };

  //get the profile of a user
  const getProfile = async () => {
    const auth_url = "https://graph.microsoft.com/v1.0/";
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    await pureAxios
      .get(auth_url + "me", {
        headers,
      })
      .then(
        async (res) => {
          const user_id = res.data.id;
          getEmpId(auth_url, headers, user_id);
          const data = {
            ...res.data,
            role: localStorage.getItem("roles"),
            jeanAuth: localStorage.getItem("mje_authorized"),
            jeanZones: localStorage.getItem("mje_zones"),
            jeanZoneCount: localStorage.getItem("mje_zones_count"),
          };
          getGroups();
          setUser(data);
        },
        (error) => {
          if (error.response.status === 401) {
            if (localStorage.getItem("token")) {
              localStorage.clear();
              window.location.href = "/";
            }
          }
        }
      );
  };

  const getProfilePhoto = async () => {
    const token = localStorage.getItem("token");
    await pureAxios
      .get("https://graph.microsoft.com/v1.0/me/photo/$value", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "arraybuffer",
      })
      .then((res) => {
        const base64 = btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        setUser((prevState) => ({
          ...prevState,
          profilePhoto: `data:image/png;base64,${base64}`,
        }));
        localStorage.setItem("ImgData", `data:image/png;base64,${base64}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logout = () => {
    instance.logoutPopup().then(
      () => {
        localStorage.clear();
        window.location.href = "/"; // replace this with something better
      },
      (error) => {}
    );
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        setLoggedIn,
        logout,
        user,
        login,
      }}
    >
      {(user && loggedIn && children) || (!user && !loggedIn && children)}
    </AuthContext.Provider>
  );
};
