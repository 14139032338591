import i18n from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import esTranslation from "./locales/es.json";
import deTranslation from "./locales/de.json";
import enTranslation from "./locales/en.json";
import Cookies from "js-cookie";

const allowedLanguages = ["en", "es", "de"];
const fallbackLanguage = "en";

// Check if the language stored in localStorage is allowed, if not, set it to the fallback language
const storedLanguage = Cookies.get("selectedLanguage")
  ? Cookies.get("selectedLanguage")
  : localStorage.getItem("i18nextLng");
if (storedLanguage && !allowedLanguages.includes(storedLanguage)) {
  localStorage.setItem("i18nextLng", fallbackLanguage);
} else {
  localStorage.setItem("i18nextLng", storedLanguage);
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
      de: {
        translation: deTranslation,
      },
    },
    fallbackLng: fallbackLanguage,
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
