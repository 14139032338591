import React, { createContext, useState, useCallback, useContext } from "react";
import { axios } from "../utils/axios";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { AuthContext } from "./auth";
import { saveAs } from "file-saver";

// const dataObj = require('../../reviewer/comment.js/dataObj');
export const RequestsContext = createContext({});

//const [rsstate,setrsstate] = useState(false);
// {/* <script src="http://code.jquery.com/jquery-1.11.0.min.js"></script>
function delay(n) {
  return new Promise(function (resolve) {
    setTimeout(resolve, n * 1000);
  });
}

async function row_update1() {
  window.location.reload();
  //await delay(7);
  toast("Your request has been cancelled successfully");
  //   document.getElementById('btn_After_Cancel_Reqstatus').load(window.location.href + " btn_After_Cancel_Reqstatus");
  //WE can also refresh div ids instead!
  //
}
export const row_update = (rowData) => {
  //
  toast("Your request has been cancelled successfully");
  axios
    .post("/requests/After_Cancel", { rowData: rowData })
    .then((response) => {
      row_update1();
    });
};

export const RequestsProvider = ({ children }) => {
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);
  const [request, setRequest] = useState({});
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [sheet, setSheet] = useState(null);
  const [level3Data, setlevel3Data] = useState(null);

  // Reviewer list State Start
  const [commentsDialog, setCommentsDialog] = useState(false);
  const [checkToggle, setCheckToggle] = useState(false);
  const [disableAssinghandler, setDisableAssinghandler] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [assignedEmail, setAssignedEmail] = useState();
  const [level2CircleColor, setlevel2CircleColor] = useState(false);
  const [isHide, setIsHide] = useState(true);
  const [redisLoading, setRedisLoading] = useState(false);
  const { user } = useContext(AuthContext);

  // Reviewer list State End

  const reset = () => {
    setRequest({});
    setErrors({});
    setSheet(null);
    setlevel3Data(null);
  };

  const fetch = useCallback(() => {
    setLoading(true);
    axios.get("/requests").then(
      (res) => {
        setLoading(false);
        const response = res.data.data;
        setRequests(response);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, [setLoading, setRequests]);

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const create = (data) => {
    toast(
      "Checking your file could take upto 5 minutes, please do not close the tab"
    );
    data.parking_status = "Not Parked Yet";
    data.errors = errors;
    data.sheet = sheet;
    const filename = data.name;

    setLoading(true);
    axios.post("/requests", data).then(
      (res) => {
        setLoading(false);
        const request = res.data.data[0];
        // const fileBase64 =  localStorage.getItem(`${filename}`)
        // localStorage.removeItem(`${filename}`)
        // localStorage.setItem(`${request.id}`, fileBase64)
        let dataObj = {
          fileName: filename,
          id: request.id,
        };
        axios.post("/requests/updateRedisData", dataObj);
        navigate(`/request/${request.id}`);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const update = (newAmount, err, sheet, pDate) => {
    const payload = {
      id: request.id,
      newAmount,
      sheet,
      postingDate: pDate,
      errors: Object.keys(err).length > 0 ? err : null,
    };
    setErrors({});
    setRequest({});
    setLoading(true);
    axios.put("/requests", payload).then(
      (res) => {
        setLoading(false);
        info(payload.id);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const info = (id) => {
    axios.get(`/requests/${id}`).then(
      (res) => {
        setLoading(false);
        let response = res.data.data;
        response.request.riskScore = response.request.riskScore
          ? JSON.parse(response.request.riskScore.score)
          : [];
        setRequest(response.request);
        if (response.request.parking_status !== "Not Parked Yet") {
          setlevel3Data(JSON.parse(response.level3Data.response));
        }
        if (response.errors) setErrors(JSON.parse(response.errors.errors));
        else setErrors({});
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  // REviewer List  Start
  const updatePostingStatus = (e, data) => {
    data.posting_status = e.target.value;
    //document.getElementById('postingstatusid').innerHTML.reload;
    //
    const payload = {
      data: data,
      postingStatus: e.target.value,
    };

    //
    // axios.post('/requests/getlatestcomment',data).then(
    //   (res) =>
    //  {
    //
    //  });
    //
    axios.post("/requests/update-status", payload).then((res) => {
      toast(
        `Posting Status of MJE - ${data.id} is set to "${e.target.value}" successfully`
      );

      //  if(e.target.value !== "Success" && data.comm)
      //  {
      //     //User should add a comment mandatory
      //     toast(`Its mandatory to add a comment`)

      //  }
      //

      //delay(100);
      window.location.reload();
      //  toast("Please make sure to add a comment")
    });
  };

  const ticketAssignHandler = (id) => {
    const payload = {
      requestID: id,
      reviewerName: user?.mail,
    };
    axios.post(`/requests/assign-ticket-to-me`, payload).then((res) => {
      toast(`Ticket MJE - ${id} assigned to you successfully.`);
      setDisableAssinghandler(true);
      setAssignedEmail(payload.reviewerName);
      // navigate('/')
      window.location.reload();
    });
  };

  const downloadMje = (data) => {
    toast("Your download will begin shortly");
    axios.post("/requests/mjedwn", data).then((res) => {
      saveAs(res.data.baseSix, `MJE-${data.id}.xls`);
    });
  };
  const downloadFileAzure = (fileName, data) => {
    toast("Your download will begin shortly");
    let sendInfo = {
      data: data,
      user: user?.role,
      fileName: fileName,
    };
    axios.post("/requests/downloadFile", sendInfo).then((res, err) => {
      // saveAs(res.data.baseSix, res.data.fileName)
      if (res.data.fileExt == ".pdf") {
        saveAs(
          `data:application/pdf;base64,${res.data.baseSix}`,
          res.data.fileName
        );
      } else if (res.data.fileExt == ".jpeg") {
        saveAs(`data:image/jpeg;base64,${res.data.baseSix}`, res.data.fileName);
      } else if (res.data.fileExt == ".jpg") {
        saveAs(`data:image/jpg;base64,${res.data.baseSix}`, res.data.fileName);
      } else if (res.data.fileExt == ".png") {
        saveAs(`data:image/png;base64,${res.data.baseSix}`, res.data.fileName);
      } else if (res.data.fileExt == ".xls") {
        saveAs(
          `data:application/vnd.ms-excel;base64,${res.data.baseSix}`,
          res.data.fileName
        );
      } else if (res.data.fileExt == "xlsx") {
        saveAs(
          `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.file}`,
          res.data.fileName
        );
      }
    });
  };

  const fileToRedis = (dataObj) => {
    setRedisLoading(true);
    axios.post("/requests/saveToRedis", dataObj).then((res) => {
      if (res.data.message === "Success") {
        setRedisLoading(false);

        setlevel2CircleColor(false);
        setIsHide(false);
      }
    });
  };
  const uploadFileToAzure = (event, data) => {
    fileToBase64(event.target.files[0], (err, result) => {
      if (result) {
        const formData = new FormData();
        formData.append("file", event.target.files[0]);
        formData.append("fileName", event.target.files[0].name);
        formData.append("data", JSON.stringify(data));
        formData.append("fileData", result);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        axios.post("/requests/uploadFile", formData, config).then((res) => {
          toast("File uploaded Successfully");
          navigate("/");
        });
      }
    });
  };
  const fetchListFromAzure = (data) => {
    setAllFiles([]);

    if (checkToggle == true) {
      setCheckToggle(false);
      setAllFiles([]);
    }
    if (checkToggle == false) {
      axios.post("/requests/fecthAllFiles", data).then((res) => {
        if (res.data.length == 0) {
          toast("No Request Docs found");
        } else {
          setAllFiles(res.data);
        }
      });
    }
  };
  function handleRowClick(event, rowData) {
    navigate(`/request/${rowData.id}`);
  }

  const reqDocs = (data) => {
    axios.post("/requests/reqDocs", data).then((res) => {
      toast("Documents Requested Successfully");
      window.location.reload(true);
    });
  };

  return (
    <RequestsContext.Provider
      value={{
        reset,
        requests,
        request,
        fetch,
        create,
        loading,
        info,
        errors,
        setErrors,
        setRequest,
        update,
        sheet,
        setSheet,
        level3Data,
        downloadMje,
        downloadFileAzure,
        uploadFileToAzure,
        fetchListFromAzure,
        allFiles,
        checkToggle,
        handleRowClick,
        updatePostingStatus,
        ticketAssignHandler,
        reqDocs,
        commentsDialog,
        setCommentsDialog,
        row_update,
        disableAssinghandler,
        assignedEmail,
        fileToRedis,
        setlevel2CircleColor,
        level2CircleColor,
        setIsHide,
        isHide,
        setRedisLoading,
        redisLoading,
      }}
    >
      {children}
    </RequestsContext.Provider>
  );
};
