import pureAxios from "axios";

let baseurl = process.env.REACT_APP_BACKEND_URL;

export const baseURL = baseurl;

export const Jeanaxios = pureAxios.create({
  baseURL,
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Role: localStorage.getItem("jean_roles"),
      Zone: localStorage.getItem("mje_zone_selected"),
      Language: localStorage.getItem("i18nextLng"),
      snowToken: `${localStorage.getItem("snow_api_access_token")}`,
    },
  },
});

export const axios = pureAxios.create({
  baseURL,
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Role: localStorage.getItem("roles"),
    },
  },
});

export const JeanNewaxios = pureAxios.create({
  baseURL,
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  },
});

//TO BE USED AFTER APIS

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    if (error?.response?.status === 401) {
      if (localStorage.getItem("token")) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

Jeanaxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    if (error?.response?.status === 405 || error?.response?.status === 401) {
      if (localStorage.getItem("token")) {
        localStorage.clear();
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);
