import React, { createContext, useState, useCallback, useContext, useEffect } from "react";
import { axios } from "../utils/axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from './auth';
import { saveAs } from 'file-saver';
import moment from 'moment'

export const CognosmjeAdminContext = createContext({});

export const CognosmjeAdminProvider = ({ children }) => {
  const navigate = useNavigate();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [roleUpdateLoading, setRoleUpdateLoading] = useState(false);
  const [downloadJournalLoading, setDownloadJournalLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [roles, setRoles] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [downloadJournalData, setDownloadJournalData] = useState([]);
  const [originalRoles, setOriginalRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [zone, setZone] = useState([]);
  const [showAddUserRole, setShowAddUserRole] = useState(false);
  const [showEditUserRole, setShowEditUserRole] = useState(false);
  const [company, setCompany] = useState(['']);
  const [editUserData, setEditUserData] = useState({});
  // const [username, setUsername] = useState(['']);
  const [userStatus, setUserStatus] = useState(['None','Active', 'InActive']);
  const [companyCode, setCompanyCode] = useState(['']);
  const [periods, setPeriods] = useState(['']);
  const [reviewer, setReviewer] = useState(['None','Yes', 'No']);
  const [approver, setApprover] = useState(['None','Yes', 'No']);
  const [filter, setFilter] = useState({
    // 'username': '',
    'company': '',
    'userStatus': '',
    'companyCode': '',
    'zone': '',
    'reviewer': '',
    'approver': ''
  });
  const fetchRoles = (value) => {
    setSearch(value)
    setLoading(true);
    axios.post("/cognosmje/admin/roles", {
      email: value.toLowerCase()
    }).then(
      (res) => {
        setLoading(false);
        const response = res.data.roles;
        setFilter({
          'company': '',
          'userStatus': '',
          'companyCode': '',
          'zone': '',
          'reviewer': '',
          'approver': ''
        })
        setRoles(response);
        setOriginalRoles(response);
        setZone(['None', ...new Set(response.map((j) => j.zone).sort())]);
        setCompany(['None', ...new Set(response.map((j) => j.company).sort())]);
        setCompanyCode(['None', ...new Set(response.map((j) => j.company_group).sort())]);
        // setUsername(['None', ...new Set(response.map((j) => j.username))]);
      },
      (error) => {
        setRoles([]);
        setLoading(false);
        setZone(['None']);
        setCompany(['None']);
        setCompanyCode(['None']);
      }
    );
  };

  const addUserRole = (role, reset) => {
    const data = {
      username: role.username,
      company_group: role.company_group,
      user_status: role.user_status ? 'Active' : 'InActive',
      company: role.company,
      email: role.email,
      zone: role.zone,
      j_type: role.j_type,
      main_company_user: role.main_company_user ? 'Yes' : 'No',
      main_Approver_user: role.main_Approver_user ? 'Yes' : 'No',
      reviewer: role.reviewer ? 'Yes' : 'No',
      approver: role.approver ? 'Yes' : 'No',
    }
    setAddLoading(true)
    axios.post('/cognosmje/admin/role', data).then((res) => {
      // setRoles(roles.push(data))
      toast(`Role added succesfully.`, {
        className: {
          background: "rgb(250, 176, 5) !important",
          color: "white !important",
          fontWeight: "bold"
        },
      })
      reset();
      setShowAddUserRole(false);
      setAddLoading(false);
    })
  }

  const editUserRole = (role, reset) => {
    const data = {
      username: role.username,
      company_group: role.company_group,
      user_status: role.user_status ? 'Active' : 'InActive',
      company: role.company,
      email: role.email,
      zone: role.zone,
      j_type: role.j_type,
      main_company_user: role.main_company_user ? 'Yes' : 'No',
      main_Approver_user: role.main_Approver_user ? 'Yes' : 'No',
      reviewer: role.reviewer ? 'Yes' : 'No',
      approver: role.approver ? 'Yes' : 'No',
    }
    setEditLoading(true)
    axios.post('/cognosmje/admin/roles/' + role.id, data).then((res) => {
      toast(`Role Updated succesfully.`, {
        className: {
          background: "rgb(250, 176, 5) !important",
          color: "white !important",
          fontWeight: "bold"
        },
      })
      reset();
      setShowEditUserRole(false)
      fetchRoles(search)
      setEditLoading(false)
    })
  }

  const rolesFilter = (props, value) => {
    const filterUpdate = {
      ...filter,
      [props]: value
    }
    setFilter(filterUpdate);
    let filterRole = originalRoles;
    // if (filterUpdate['period'] && filterUpdate['period'] !== '') {
    //   filterJournals = filterJournals.filter((j) => moment(j.period_date).isAfter(filterUpdate['period']) || moment(j.period_date).isSame(filterUpdate['period']))
    // }
    if (filterUpdate['company'] && filterUpdate['company'] !== '') {
      filterRole = filterRole.filter((j) => j.company === filterUpdate['company'])
    }
    if (filterUpdate['zone'] && filterUpdate['zone'] !== '') {
      filterRole = filterRole.filter((j) => j.zone === filterUpdate['zone'])
    }
    // if (filterUpdate['companyCode'] && filterUpdate['companyCode'] !== '') {
    //   filterRole = filterRole.filter((j) => j.company_group === filterUpdate['companyCode'])
    // }
    if (filterUpdate['userStatus'] && filterUpdate['userStatus'] !== '') {
      let value = filterUpdate['userStatus'] === "Active" ? true : false
      // if (value)
      //   filterRole = filterRole.filter((j) => j.user_status)
      // else
      //   filterRole = filterRole.filter((j) => !j.user_status)
      filterRole = filterRole.filter((j) => j.user_status === filterUpdate['userStatus'])

    }
    if (filterUpdate['reviewer'] && filterUpdate['reviewer'] !== '') {
      // let value = filterUpdate['reviewer'] === "Yes" ? true : false
      filterRole = filterRole.filter((j) => j.reviewer === filterUpdate['reviewer'])
      // if (value)
      //   filterRole = filterRole.filter((j) => j.reviewer)
      // else
      //   filterRole = filterRole.filter((j) => !j.reviewer)
    }
    if (filterUpdate['approver'] && filterUpdate['approver'] !== '') {
      let value = filterUpdate['approver'] === "Yes" ? true : false
      filterRole = filterRole.filter((j) => j.approver === filterUpdate['approver'] )
      // if (value)
      //   filterRole = filterRole.filter((j) => j.approver)
      // else
      //   filterRole = filterRole.filter((j) => !j.approver)
    }
    setRoles(filterRole)
  }


  const handleDownload = (setCsvData) => {
    setDownloadLoading(true)
    axios.get('/cognosmje/admin/exportRoles')
      .then(function (response) {
        setDownloadLoading(false)
        setDownloadData(response?.data?.roles);
        setCsvData(true)
      })
      .catch(function (error) {
        setDownloadLoading(false)
      });
  }

  const handleJournalDownload = (setCsvJournalData, period) => {
    setDownloadJournalLoading(true)
    axios.get('/cognosmje/admin/exportJournals?period=' + period)
      .then(function (response) {
        setDownloadJournalLoading(false)
        setDownloadJournalData(response?.data?.journalDetails);
        setCsvJournalData(true)
      })
      .catch(function (error) {
        setDownloadJournalLoading(false)
      });
  }

  const fetchPeriods = () => {
    setLoading(true)
    axios.get('/cognosmje/admin/periods')
      .then(function (response) {
        setPeriods(response?.data?.periods)
        setLoading(false)
      })
      .catch(function (error) {
        setLoading(false)

      });
  }

  const updateRoles = (file, setFile) => {
    setRoleUpdateLoading(true)
    fileToBase64(file, (err, result) => {
      if (result) {
        const formData = new FormData();
        formData.append('file', file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        axios.post('/cognosmje/load/reload/roles', formData, config).then((res) => {
          setFile(undefined)
          toast(`Roles Updated succesfully.`, {
            className: {
              background: "rgb(250, 176, 5) !important",
              color: "white !important",
              fontWeight: "bold"
            },
          })
          setRoleUpdateLoading(false);
        })
      }
    })
  }

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }
  return (
    <CognosmjeAdminContext.Provider
      value={{
        loading,
        roles,
        filter,
        // username,
        company,
        userStatus,
        companyCode,
        zone,
        reviewer,
        approver,
        showAddUserRole,
        showEditUserRole,
        editUserData,
        downloadLoading,
        roleUpdateLoading,
        downloadData,
        addLoading,
        editLoading,
        setRoleUpdateLoading,
        handleDownload,
        setEditUserData,
        setShowAddUserRole,
        setShowEditUserRole,
        rolesFilter,
        addUserRole,
        editUserRole,
        fetchRoles,
        fetchPeriods,
        periods,
        downloadJournalData,
        downloadJournalLoading,
        handleJournalDownload,
        updateRoles,
      }}
    >
      {children}
    </CognosmjeAdminContext.Provider>
  );
};
