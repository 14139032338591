import React from "react";
import pureAxios from "axios";
import { Jeanaxios } from "../../utils/axios";

const getGroups = async (e, flag = true) => {
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
  await pureAxios
    .get(
      "https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$top=999",
      {
        headers,
      }
    )
    .then(
      (res) => {
        const user_groups = res.data.value;
        const app_stage = process.env.REACT_APP_ENVIRONMENT.toUpperCase();
        const user_selected_zone = e.toUpperCase();
        const regex = new RegExp(`(JEAN_${app_stage}_${user_selected_zone}_)`);
        let role_list = [];
        user_groups.filter((item) => {
          const match = item.displayName.match(regex);
          if (match) {
            const stringAfterRegex = item.displayName.slice(match[0].length);
            role_list.push(stringAfterRegex.replace("-", " "));
          }
          return match;
        });
        role_list = Array.from(new Set(role_list));
        role_list.sort((a, b) => b.localeCompare(a));

        // defaults
        Jeanaxios.defaults.headers.common["Role"] = `${role_list}`;
        Jeanaxios.defaults.headers.common["Zone"] = `${e.toUpperCase()}`;

        if (flag) {
          localStorage.setItem("jean_roles", role_list);
        } else {
          localStorage.setItem("jean_temp_roles", role_list);
        }
      },
      (error) => {
        if (error.response.status === 401) {
          if (localStorage.getItem("token")) {
            localStorage.clear();
            window.location.href = "/";
          }
        }
      }
    );
};

export default getGroups;
