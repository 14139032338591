import React, { useContext, useEffect } from "react";
import { AuthContext } from "./contexts/auth";
import { Link, useLocation } from "react-router-dom";
import TopBar from "./common/cognos/TopBar/TopBar";
import Navbar from "./common/navbar/Navbar";
import Footer from "./common/cognos/Footer/Footer";
import LoggedOutRoutes from "./routes/LoggedOutRoutes";
import LoggedInRoutes from "./routes/LoggedInRoutes";
import { Helmet } from "react-helmet";

function App() {
  const { loggedIn } = useContext(AuthContext);
  const location = useLocation();
  const url = window.location.pathname;
  const showTopbar = url.startsWith("/v2") ? false : true;

  return (
    <div className="App">
      <Helmet>
        <script type="text/javascript">
          {`(function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script', '${process.env.REACT_APP_PROJ_ID}');`}
        </script>
      </Helmet>
      <header className="App-header">
        <div className="page">
          <div className="flex-fill">
            {loggedIn ? (
              <>
                {showTopbar == true &&
                  !["/login"].includes(location?.pathname) && <TopBar />}
                {showTopbar == false &&
                  !["/login"].includes(location?.pathname) &&
                  !["/v2/home"].includes(location?.pathname) && <Navbar />}
                <div className="my-3 my-md-5">
                  <LoggedInRoutes />
                </div>
              </>
            ) : (
              <LoggedOutRoutes />
            )}
          </div>
        </div>
      </header>
      <br />
      <Footer />
    </div>
  );
}

export default App;
