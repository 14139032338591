import React, { createContext, useState, useCallback, useContext, useEffect } from "react";
import { axios } from "../utils/axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from './auth';
import { saveAs } from 'file-saver';
import moment from 'moment'
export const CognosmjeContext = createContext({});

export const CognosmjeProvider = ({ children }) => {
  const navigate = useNavigate();
  const [selectedApprovalJournalCount, setSelectedApprovalJournalCount] = useState(0);
  const [selectedApprovalJournal, setSelectedApprovalJournal] = useState([]);
  const [selectedRejectedJournal, setSelectedRejectedJournal] = useState([]);
  const [journals, setJournals] = useState([]);
  const [selectedJournal, setSelectedJournal] = useState({});
  const [originalJournals, setOriginalJournals] = useState([]);
  const [counterReset, setCounterReset] = useState(true);
  const [loading, setLoading] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [errors, setErrors] = useState({});
  const { user } = useContext(AuthContext)
  const [zone, setZone] = useState([]);
  const [period, setPeriod] = useState([]);
  const [company, setCompany] = useState([]);
  const [journalType, setJournalType] = useState([]);
  const [changeBy, setChangeBy] = useState([]);
  const [evidence, setEvidence] = useState(["None", "Yes", "No"]);
  const [approvalStatus, setApprovalStatus] = useState(["None", "Approved", "Pending", "Rejected"]);
  const [reviewerStatus, setReviewerStatus] = useState(["None", "Reviewed", "Pending", "Rejected"]);

  const [filter, setFilter] = useState({
    zone: '',
    company: '',
    journalType: '',
    changeBy: '',
    evidence: '',
    period: '',
    approvalStatus: '',
    reviewerStatus: 'Pending'
  });

  useEffect(() => {
    setSelectedApprovalJournalCount(selectedApprovalJournal.length + selectedRejectedJournal.length); // This will always use latest value of count
  }, [selectedApprovalJournal, selectedRejectedJournal]);

  const fetchReviewerJournals = () => {
    setLoading(true);
    axios.get("/cognosmje/reviewer").then(
      (res) => {
        setLoading(false);
        const response = res.data.journals;

        setJournals(response.filter((j) => j.review_status === filter.reviewerStatus));
        setOriginalJournals(response);
        setZone(['None', ...new Set(response.map((j) => j.zone).sort())]);
        setPeriod(['None', ...new Set(response.map((j) => j.period))]);
        setCompany(['None', ...new Set(response.map((j) => j.company).sort())]);
        setChangeBy(['None', ...new Set(response.map((j) => j.modification_user).sort())]);
        setJournalType(['None', ...new Set(response.map((j) => j.j_type).sort(function (a, b) { return a - b }))]);
      },
      (error) => {
        setJournals([]);
        setOriginalJournals([]);
        setLoading(false);
        setZone(['None']);
        setPeriod(['None']);
        setCompany(['None']);
        setChangeBy(['None']);
        setJournalType(['None']);
      }
    );
  };


  const fetchJournalDetails = (id) => {
    setLoadingDetails(true);
    axios.get("/cognosmje/reviewer/journaldetails/" + id).then(
      (res) => {
        const response = res.data;
        setJournals(journals.map((j) => {
          if (j.id === id) {
            j = {
              ...j,
              ...response
            }
          }
          return j
        }))
        setLoadingDetails(false)
      },
      (error) => {
        setLoadingDetails(false);
      }
    );
  };

  const addSelectedApprovalJournal = (journalObject) => {
    setSelectedRejectedJournal(selectedRejectedJournal.filter((j) => j.id !== journalObject.id))
    setSelectedApprovalJournal(selectedApprovalJournal.concat(journalObject))
  }

  const addSelectedRejectedJournal = (journalObject) => {
    setSelectedApprovalJournal(selectedApprovalJournal.filter((j) => j.id !== journalObject.id))
    setSelectedRejectedJournal(selectedRejectedJournal.concat(journalObject))
  }

  const removeSelectedApprovalJournal = (journalObject) => {
    setSelectedApprovalJournal(selectedApprovalJournal.filter((j) => j.id !== journalObject.id))
  }

  const removeSelectedRejectedJournal = (journalObject) => {
    setSelectedRejectedJournal(selectedRejectedJournal.filter((j) => j.id !== journalObject.id))
  }

  const addReviewerComment = (comment, id) => {
    const data = {
      name: user.displayName,
      email: user.mail,
      journal_id: id,
      time: moment().toISOString(),
      comment
    }
    setJournals(
      journals.map((j) => {
        if (j.id === id) {
          j.comments = [data].concat(j.comments)
        }
        return j
      }
      ));
    axios.post('/cognosmje/reviewer/comment', data).then((res) => {

    })
  }

  const addApprovalSelectedJournal = (jObj, approvalValue) => {
    setJournals(journals.map((j) => {
      if (j.id === jObj.id) {
        j.selectedApproval = approvalValue
      }
      return j;
    }))
    setSelectedApprovalJournal(selectedApprovalJournal.map((j) => {
      if (j.id === jObj.id) {
        j.selectedApproval = approvalValue
      }
      return j;
    }))
  }

  const submitJournals = () => {
    const updateJounals = {
      rejected: selectedRejectedJournal,
      approval: selectedApprovalJournal
    }
    let checkReject = true;
    let checkApprove = true;

    updateJounals.rejected.forEach((r) => {
      if (!r.comments || r?.comments?.length === 0) {
        toast.warn(`Please add the comment in Journal No ${r.j_no}`, {
          className: {
            backgroundColor: "rgb(244, 224, 15) !important",
            color: "black !important",
            fontWeight: "bold"
          },
        })
        checkReject = false
      } else {
        checkReject = true
      }
    })
    updateJounals.approval.forEach((r) => {
      if (!r?.selectedApproval) {
        toast.warn(`Please select the approver in Journal No ${r.j_no}`, {
          className: {
            backgroundColor: "rgb(244, 224, 15) !important",
            color: "black !important",
            fontWeight: "bold"
          },
        })
        checkApprove = false
      } else {
        checkApprove = true
      }
    })
    if (checkApprove && checkReject) {
      setLoading(true);
      axios.post('/cognosmje/reviewer/journals', updateJounals).then((res) => {
        setJournals(res.data.journals);
        setSelectedApprovalJournal([])
        setSelectedRejectedJournal([])
        toast(`Journals updated succesfully.`, {
          className: {
            background: "rgb(250, 176, 5) !important",
            color: "white !important",
            fontWeight: "bold"
          },
        })
        fetchReviewerJournals();
      }, (error) => {
        setLoading(false);
      })
    }
  };

  const downloadFileAzure = (id) => {
    setDownloadingFile(true)
    toast("Your download will begin shortly", {
      className: {
        background: "rgb(250, 176, 5) !important",
        color: "white !important",
        fontWeight: "bold"
      },
    })
    axios.get('/cognosmje/downloadFile/' + id).then((res, err) => {
      const data = res.data.baseSix
      const fileName = res.data.fileName

      if (res.data.fileExt == '.pdf') {
        saveAs(`data:application/pdf;base64,${res.data.baseSix}`, res.data.fileName)
      } else if (res.data.fileExt == '.jpeg') {
        saveAs(`data:image/jpeg;base64,${res.data.baseSix}`, res.data.fileName)
      } else if (res.data.fileExt == '.jpg') {
        saveAs(`data:image/jpg;base64,${res.data.baseSix}`, res.data.fileName)
      } else if (res.data.fileExt == '.png') {
        saveAs(`data:image/png;base64,${res.data.baseSix}`, res.data.fileName)
      } else if (res.data.fileExt == '.xls') {
        saveAs(`data:application/vnd.ms-excel;base64,${res.data.baseSix}`, res.data.fileName)
      } else if (res.data.fileExt == 'xlsx') {
        saveAs(`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.file}`, res.data.fileName)
      }
      else if (res.data.fileExt == '.txt') {
        saveAs(`data:/text/plain;base64,${res.data.baseSix}`, res.data.fileName)
      } 
      else if (res.data.fileExt == '.msg') {
        saveAs(`data:application/vnd.ms-outlook;base64,${res.data.baseSix}`, res.data.fileName)
      }
      
      setDownloadingFile(false);
    })
  }
  async function blobToString(blob) {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onloadend = (ev) => {
        resolve(ev.target.result);
      };
      fileReader.onerror = reject;
      fileReader.readAsText(blob);
    });
  }

  const uploadFileToAzure = (event, journal_id) => {
    fileToBase64(event.target.files[0], (err, result) => {
      if (result) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        formData.append('journal_id', journal_id);
        formData.append('fileName', event.target.files[0].name);
        formData.append('fileData', result);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };
        axios.post('/cognosmje/uploadFile', formData, config).then(
          (res) => {
            toast("File uploaded Successfully", {
              className: {
                background: "rgb(250, 176, 5) !important",
                color: "white !important",
                fontWeight: "bold"
              },
            });
            const updatedJournals = journals.map((j) => {
              if (j.id === journal_id) {
                j.evidence_included = "Yes"
                j.files.push({ 'name': event.target.files[0].name, journal_id, id: res?.data?.data?.id })
              }
              return j
            })
            setJournals(updatedJournals)
          }
        )
      }
    })
  }

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(null, reader.result)
    }
    reader.onerror = function (error) {
      cb(error, null)
    }
  }
  const journalFilter = (props, value) => {
    const filterUpdate = {
      ...filter,
      [props]: value
    }
    setFilter(filterUpdate);
    let filterJournals = originalJournals;
    if (filterUpdate['period'] && filterUpdate['period'] !== '') {
      filterJournals = filterJournals.filter((j) => j.period === filterUpdate['period'])
    }
    if (filterUpdate['company'] && filterUpdate['company'] !== '') {
      filterJournals = filterJournals.filter((j) => j.company === filterUpdate['company'])
    }
    if (filterUpdate['zone'] && filterUpdate['zone'] !== '') {
      filterJournals = filterJournals.filter((j) => j.zone === filterUpdate['zone'])
    }
    if (filterUpdate['changeBy'] && filterUpdate['changeBy'] !== '') {
      filterJournals = filterJournals.filter((j) => j.modification_user === filterUpdate['changeBy'])
    }
    if (filterUpdate['journalType'] && filterUpdate['journalType'] !== '') {
      filterJournals = filterJournals.filter((j) => j.j_type === filterUpdate['journalType'])
    }
    if (filterUpdate['evidence'] && filterUpdate['evidence'] !== '') {
      filterJournals = filterJournals.filter((j) => j.evidence_included === filterUpdate['evidence'])
    }
    if (filterUpdate['approvalStatus'] && filterUpdate['approvalStatus'] !== '') {
      filterJournals = filterJournals.filter((j) => j.approver_status === filterUpdate['approvalStatus'])
    }
    if (filterUpdate['reviewerStatus'] && filterUpdate['reviewerStatus'] !== '') {
      filterJournals = filterJournals.filter((j) => j.review_status === filterUpdate['reviewerStatus'])
    }
    setJournals(filterJournals)
  }

  const setDateFilter = (value) => {
    const filterUpdate = {
      ...filter,
      'period': value
    }
    setFilter(filterUpdate);
    fetchReviewerJournals();
  }
  return (
    <CognosmjeContext.Provider
      value={{
        selectedApprovalJournalCount,
        loading,
        loadingDetails,
        journals,
        zone,
        period,
        company,
        journalType,
        changeBy,
        evidence,
        approvalStatus,
        reviewerStatus,
        filter,
        downloadingFile,
        journalFilter,
        setDateFilter,
        selectedJournal,
        setSelectedJournal,
        addApprovalSelectedJournal,
        removeSelectedApprovalJournal,
        removeSelectedRejectedJournal,
        submitJournals,
        uploadFileToAzure,
        downloadFileAzure,
        addReviewerComment,
        addSelectedApprovalJournal,
        addSelectedRejectedJournal,
        fetchReviewerJournals,
        fetchJournalDetails,
        setErrors
      }}
    >
      {children}
    </CognosmjeContext.Provider>
  );
};
